import { Cmp, SHARED_CONST } from ":mods";

export function CSSStyler(props: {
  style: Partial<CSSStyleDeclaration>;
  onChange?: (style: Partial<CSSStyleDeclaration>) => void;
  class?: string;
}) {
  return (
    <Cmp.List.Editable
      each={props.style}
      options={SHARED_CONST.ELEMENT_STYLE_VARS}
      kebabToCamel
      class={props.class ?? "list-editable"}
      events={{
        error: (type, message) => {
          if (type === "adding_group") {
            Cmp.Alert.actions.pushToast({
              type: "error",
              message: "Cannot add group " + message,
            });
          } else if (type === "adding_group_key") {
            Cmp.Alert.actions.pushToast({
              type: "error",
              message: "Cannot add group key " + message + ", it's not a recognizd css style key!",
            });
            return;
          }
        },
        update: (items, kvp) => {
          props.onChange?.(items);
        },
        delete: (items, kvp) => {
          props.onChange?.(items);
        },
        add: (items, kvp) => {
          props.onChange?.(items);
        },
        reorder: (items, kvp) => {
          props.onChange?.(items);
        },
      }}
    />
  );
}
